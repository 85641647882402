import React from 'react';
import PropTypes from 'prop-types';
import { Rating } from 'react-simple-star-rating';
import { Row, Wrapper } from '@components/ReviewBox/style';
import ThumbUpIcon from '@components/Icons/ThumbUpIcon';
import ThumbDownIcon from '@components/Icons/ThumbDownIcon';
import theme from '@styles/theme';

ReviewBox.propTypes = {
  reservation: PropTypes.object.isRequired,
};

function ReviewBox({ reservation }) {
  return (
    <Wrapper>
      <Row>
        <p>Czy polecasz ten jacht?</p>
        {reservation.review.recommended ? <ThumbUpIcon /> : <ThumbDownIcon />}
      </Row>
      <Row>
        <p>Stan techniczny</p>
        <Rating
          initialValue={reservation.review.technicalConditionRating}
          fillColor={theme.colors.primary}
          readonly
        />
      </Row>
      <Row>
        <p>Czystość</p>
        <Rating
          initialValue={reservation.review.cleanlinessRating}
          fillColor={theme.colors.primary}
          readonly
        />
      </Row>
      <Row>
        <p>Komfort i wyposażenie</p>
        <Rating
          initialValue={reservation.review.comfortEquipmentRating}
          fillColor={theme.colors.primary}
          readonly
        />
      </Row>
      <Row>
        <p>Jakość obsługi przy przekazaniu jachtu</p>
        <Rating
          initialValue={reservation.review.serviceQualityRating}
          fillColor={theme.colors.primary}
          readonly
        />
      </Row>
      <strong>{reservation.review.comment}</strong>
    </Wrapper>
  );
}

export default ReviewBox;
