import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.error}30;
  padding: 16px;
  border: 3px solid ${({ theme }) => theme.colors.error};
  border-radius: 16px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 30px;
`;
