import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import theme from '@styles/theme';
import routes from '@routes';
import DetailsPage from '@containers/Details';
import ReviewPage from '@containers/Review';
import GlobalStyles, { PageWrapper } from '@styles/GlobalStyles';

const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PageWrapper>
          <Routes>
            <Route exact path={routes.DETAILS()} element={<DetailsPage />} />
            <Route exact path={routes.REVIEW()} element={<ReviewPage />} />
            <Route path="*" element={<DetailsPage />} />
          </Routes>
        </PageWrapper>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
