/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { RecommendedOption } from '@components/ReviewForm/Controllers/RecommendedController/style';
import ThumbUpIcon from '@components/Icons/ThumbUpIcon';
import ThumbDownIcon from '@components/Icons/ThumbDownIcon';

RecommendedController.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};

function RecommendedController({ name, label, register }) {
  return (
    <fieldset>
      <label htmlFor={name}>{label}</label>
      <RecommendedOption>
        <input {...register(name)} type="radio" value="true" id="true" />
        <label htmlFor="true">
          <ThumbUpIcon />
        </label>
        <input {...register(name)} type="radio" value="false" id="false" />
        <label htmlFor="false">
          <ThumbDownIcon />
        </label>
      </RecommendedOption>
    </fieldset>
  );
}

export default RecommendedController;
