export const initialState = {
  isLoading: false,
  reservation: null,
  isSuccess: false,
  isError: false,
};

export function init(state) {
  return state;
}

export function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'toggleLoading': {
      return { ...state, isLoading: !state.isLoading };
    }
    case 'updateReservation': {
      return { ...state, reservation: payload };
    }
    case 'setSuccess': {
      return { ...state, isSuccess: true };
    }
    case 'setError': {
      return { ...state, isError: true };
    }
    default: {
      throw new Error();
    }
  }
}
