import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Rating } from 'react-simple-star-rating';
import { RatingControllerLabel } from '@components/ReviewForm/Controllers/RatingController/style';
import theme from '@styles/theme';

RatingController.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  isInvalid: PropTypes.bool,
};

function RatingController({ name, label, control, isInvalid }) {
  return (
    <fieldset>
      <RatingControllerLabel htmlFor={name} isInvalid={isInvalid}>
        {label}
      </RatingControllerLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Rating
            onClick={onChange}
            initialValue={value}
            id={name}
            fillColor={theme.colors.primary}
          />
        )}
      />
    </fieldset>
  );
}

RatingController.defaultProps = {
  isInvalid: false,
};

export default RatingController;
