import styled from '@emotion/styled';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;

  label,
  textarea {
    display: block;
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }

  span {
    &.error {
      color: red;
      margin-top: 0.5em;
      font-size: 16px;
      display: block;
    }
  }

  button {
    margin-top: 2em;
  }
`;

export const CommentSection = styled.div`
  label {
    font-size: 16px;
    margin-top: 2em;
    display: block;
  }

  textarea {
    margin-top: 1em;
    width: 100%;
    min-height: 100px;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    border-color: lightgray;
  }
`;

export const RatingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  fieldset {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
