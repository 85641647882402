import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Logo from '@components/Logo';
import { Title, Wrapper, Icon } from '@components/DetailsHeaderBar/style';
import routes from '@routes';
import PrinterIcon from '@assets/icons/print.svg';

function DetailsHeaderBar() {
  const { pathname } = useLocation();
  const match = matchPath({ path: routes.DETAILS() }, pathname);

  const reservationNumber = useMemo(() => {
    if (match && match.params?.reservationNumber) {
      if (match.params['*']) {
        return `${match.params.reservationNumber}/${match.params['*']}`;
      }
      return match.params.reservationNumber;
    }

    return null;
  }, [match]);

  return (
    <Wrapper>
      <style>{`@media print {header {padding-top: 0 !important;}}`}</style>
      <div className="summary">
        <Logo style={{ width: '60px', height: '60px' }} />
        <Title>
          Podsumowanie rezerwacji - <span>{reservationNumber}</span>
        </Title>
      </div>
      <div className="printer">
        <style>{`@media print {.printer {display: none;}}`}</style>
        <Icon onClick={window.print}>
          <img src={PrinterIcon} alt="wydrukuj" />
        </Icon>
      </div>
    </Wrapper>
  );
}

export default DetailsHeaderBar;
