const theme = {
  colors: {
    primary: '#0077B6',
    error: '#af1111',
    success: '#72AB4A',
  },
  loader: {
    backgroundColor: 'transparent',
  },
  statuses: {
    temporary: '#9CB5D9',
    payment_ready: '#65ACC2',
    expired: '#919EAB',
    confirmed: '#72AB4A',
    canceled: '#FE8848',
  },
};

export default theme;
