import styled from '@emotion/styled';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
  align-items: flex-start;
  gap: 30px;
  padding: 16px;
  strong {
    font-weight: 500;
    font-size: 16px;
    display: block;
  }

  .payments {
    flex: 1 1 100px;
    min-width: 450px;
  }

  &.footer div {
    font-style: italic;
    color: #666;
  }

  &.footer {
    display: none;
    text-align: center;
  }
`;

export const Section = styled.div`
  align-self: flex-start;
  flex: 1 1 200px;
  h2 {
    display: block;
    padding-bottom: 5px;
    border-bottom: 1px #ddd solid;
    margin-bottom: 10px;
    font-size: 16px;
  }

  &.lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 30px;
    row-gap: 25px;
  }

  .list {
    align-self: flex-start;
    flex: 1 1 220px;

    h2 {
      display: flex;
      justify-content: space-between;

      a {
        font-size: 12px;
        color: #0077b6;
        display: block;
        color: rgb(0, 119, 182);
        border: 1px dashed rgb(0, 119, 182);
        border-radius: 20px;
        padding: 0 15px;
        margin-top: -2px;
      }
    }
  }

  li {
    margin-bottom: 5px;
  }
`;

export const Note = styled.strong`
  white-space: pre-line;
`;
