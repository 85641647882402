import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response),
);

const pathVersion = {
  basic: 'v1',
};

const APIRoutes = {
  RESERVATION: (token, reservationNumber) =>
    `/${pathVersion.basic}/${token}/${reservationNumber}/status`,
  REVIEW: (reservationSid, reviewInvitationToken) =>
    `/${pathVersion.basic}/${reservationSid}/${reviewInvitationToken}/review`,
  SEND_REVIEW: (token, reviewInvitationToken) =>
    `/${pathVersion.basic}/${token}/${reviewInvitationToken}/send_review`,
};

export { API, APIRoutes };
