import React from 'react';
import NotFoundIcon from '@assets/icons/404.svg';
import { Icon, Text, Wrapper } from '@components/NotFound/style';

function NotFound() {
  return (
    <Wrapper>
      <Icon src={NotFoundIcon} alt="Exclamation mark describing no record found" />
      <Text>Nie znaleźliśmy rezerwacji o takim numerze</Text>
    </Wrapper>
  );
}

export default NotFound;
