import React from 'react';
import PropTypes from 'prop-types';
import Logo from '@components/Logo';
import { Title, Wrapper } from '@components/ReviewHeaderBar/style';

function ReviewHeaderBar({ reservationNumber }) {
  return (
    <Wrapper>
      <div className="summary">
        <Logo style={{ width: '60px', height: '60px' }} />
        <Title>
          Dodaj opinię - <span>{reservationNumber}</span>
        </Title>
      </div>
    </Wrapper>
  );
}

ReviewHeaderBar.propTypes = {
  reservationNumber: PropTypes.string.isRequired,
};

export default ReviewHeaderBar;
