import styled from '@emotion/styled';

export const Box = styled.div`
  padding: 16px;
  background: #dddddd30;
  border-radius: 16px;
  margin-bottom: 35px;
`;

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
`;

export const Icon = styled.div`
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    max-height: 18px;
    width: 20px;
  }
`;
