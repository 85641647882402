import styled from '@emotion/styled';

export const MainWrapper = styled.main`
  max-width: 520px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
  align-items: flex-start;
  gap: 30px;
  padding: 16px;
  strong {
    font-weight: 500;
    font-size: 16px;
    display: block;
  }

  .payments {
    flex: 1 1 100px;
    min-width: 450px;
  }

  &.footer div {
    font-style: italic;
    color: #666;
  }

  &.footer {
    display: none;
    text-align: center;
  }
`;

export const Section = styled.div`
  align-self: flex-start;
  flex: 1 1 200px;
  h2 {
    display: block;
    padding-bottom: 5px;
    border-bottom: 1px #ddd solid;
    margin-bottom: 10px;
    font-size: 16px;
  }

  h2.formtitle {
    margin-top: 0.5em;
    font-size: 2em;
    line-height: 1.3em;
    font-weight: 500;
    color: #0077b6;
  }

  &.lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 30px;
    row-gap: 25px;
  }

  .list {
    align-self: flex-start;
    flex: 1 1 220px;
  }

  li {
    margin-bottom: 5px;
  }
`;
