import styled from '@emotion/styled';

export const Btn = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px 15px;
  font-weight: bold;
`;
