import styled from '@emotion/styled';

export const RecommendedOption = styled.div`
  display: flex;
  gap: 8px;

  input[type='radio'] {
    display: none;

    + label {
      opacity: 0.5;
      filter: grayscale(1);
    }

    &:checked + label {
      opacity: 1;
      filter: none;
    }
  }
`;
