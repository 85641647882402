const CURRENCIES = {
  zł: 'PLN',
};

export const formatCurrency = (value, currency) =>
  new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: CURRENCIES[currency],
    maximumFractionDigits: 0,
    useGrouping: 'always',
  }).format(value);
