import React from 'react';
import PropTypes from 'prop-types';
import { Col, Wrapper } from '@components/StatusBar/style';

const STATUSES = {
  temporary: 'Tymczasowa',
  payment_ready: 'Oczekująca na płatność',
  expired: 'Wygasła',
  confirmed: 'Potwierdzona',
  canceled: 'Anulowana',
};

function StatusBar({ state, reservationNumber }) {
  return (
    <Wrapper state={state}>
      <Col>
        Nr rezerwacji: <b>{reservationNumber}</b>
      </Col>
      <Col>
        Aktualny status: <b>{STATUSES[state]}</b>
      </Col>
    </Wrapper>
  );
}

StatusBar.propTypes = {
  state: PropTypes.oneOf(['temporary', 'payment_ready', 'expired', 'confirmed', 'canceled'])
    .isRequired,
  reservationNumber: PropTypes.string.isRequired,
};

export default StatusBar;
