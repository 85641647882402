import React from 'react';
import PropTypes from 'prop-types';
import { Box, Title, Detail, Details, Icon } from '@components/PartnerBox/style';
import PhoneIcon from '@assets/icons/phone.svg';
import EmailIcon from '@assets/icons/mail.svg';

function PartnerBox({ partner }) {
  return (
    <Box className="address">
      <style>{`@media print {.address {padding: 0 16px;}}`}</style>
      <Title>{partner.name}</Title>

      <Details>
        <Detail>
          <Icon>
            <img src={PhoneIcon} alt="phone" />
          </Icon>
          {partner.phone}
        </Detail>
        <Detail>
          <Icon>
            <img src={EmailIcon} alt="email" />
          </Icon>
          {partner.email}
        </Detail>
      </Details>
    </Box>
  );
}

PartnerBox.propTypes = {
  partner: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default PartnerBox;
