import React from 'react';
import PropTypes from 'prop-types';
import { Btn } from '@components/Button/style';

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

function Button({ onClick, type, children }) {
  return (
    <Btn type={type} className="printer" onClick={onClick}>
      {children}
    </Btn>
  );
}

Button.defaultProps = {
  onClick: () => {},
  type: 'button',
};

export default Button;
