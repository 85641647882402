/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { reducer, initialState, init } from '@containers/Review/reducer';
import { API, APIRoutes } from '@api';
import NotFound from '@components/NotFound';
import { Loader } from '@styles/GlobalStyles';
import Logo from '@components/Logo';
import { MainWrapper, Row, Section } from '@containers/Review/style';
import ReviewForm from '@components/ReviewForm';
import ReviewBox from '@components/ReviewBox';
import ReviewHeaderBar from '@components/ReviewHeaderBar';
import ErrorBar from '@components/ErrorBar';
import SuccessBox from '@components/SuccessBox';

function ReviewPage() {
  const { reservationSid, reviewInvitationToken } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState, init);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'toggleLoading' });
      try {
        const {
          data: { reservation },
        } = await API.get(APIRoutes.REVIEW(reservationSid, reviewInvitationToken));

        dispatch({ type: 'updateReservation', payload: reservation });
      } catch (error) {
        if (error.status === 404) {
          setNotFound(true);
        }
      } finally {
        dispatch({ type: 'toggleLoading' });
      }
    })();
  }, []);

  if (!reservationSid || !reviewInvitationToken || notFound) {
    return <NotFound />;
  }

  if (state.isLoading) {
    return (
      <Loader>
        <Logo style={{ width: '60px', height: '60px' }} />
      </Loader>
    );
  }

  if (state.isSuccess) {
    return <SuccessBox />;
  }

  if (state.reservation) {
    const { reservation } = state;

    return (
      <MainWrapper>
        <Helmet>
          <title>
            Dodaj opinię {reservation.yachtTypeName} - {reservation.number}
          </title>
          <meta
            name="description"
            content={`Dodaj opinię ${reservation.yachtTypeName} - ${reservation.number}`}
          />
        </Helmet>

        <ReviewHeaderBar reservationNumber={reservation.number} />
        {reservation.reviewFormError && <ErrorBar message={reservation.reviewFormError} />}
        {state.isError && (
          <ErrorBar message="Wystąpił problem podczas wysyłania formularza. Spróbuj ponownie później." />
        )}

        <Row>
          <Section className="lists">
            <div className="list">
              <h2>Jacht</h2>
              <ul>
                <li>
                  <strong>{reservation.yachtTypeName}</strong>
                </li>
              </ul>
            </div>

            <div className="list">
              <h2>Okres rezerwacji</h2>
              <ul>
                <li>
                  <strong>
                    {reservation.startDate} - {reservation.endDate}
                  </strong>
                </li>
              </ul>
            </div>
          </Section>

          <Section>
            <h2 className="formtitle">Oceń jacht {reservation.yachtTypeName}</h2>
            {reservation.review ? (
              <ReviewBox reservation={reservation} />
            ) : (
              !reservation.reviewFormError && (
                <ReviewForm
                  token={reservationSid}
                  reviewInvitationToken={reviewInvitationToken}
                  dispatch={dispatch}
                />
              )
            )}
          </Section>
        </Row>
      </MainWrapper>
    );
  }

  return null;
}

export default memo(ReviewPage);
