const ROUTES = {
  DETAILS: (params) => {
    if (!params) {
      return '/:token/:reservationNumber/*';
    }

    const { token, reservationNumber } = params;

    return `/${token}/${reservationNumber}`;
  },
  REVIEW: (params) => {
    if (!params) {
      return '/:reservationSid/:reviewInvitationToken/review';
    }

    const { reservationSid, reviewInvitationToken } = params;

    return `/${reservationSid}/${reviewInvitationToken}/review`;
  },
};

export default ROUTES;
