import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { formatCurrency } from '@utils';
import Button from '@components/Button';
import { API } from '@root/api';
import { Summary, Wrapper } from '@components/PaymentBox/style';

PaymentBox.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.object,
      expireAt: PropTypes.string,
      paidAt: PropTypes.string,
      name: PropTypes.string,
      kind: PropTypes.string,
      status: PropTypes.string,
      paymentPath: PropTypes.string,
    }),
  ).isRequired,
  finalAmount: PropTypes.shape({
    currencySymbol: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  bankAccount: PropTypes.shape({
    bankAccountNumber: PropTypes.string,
    bankName: PropTypes.string,
    bicSwift: PropTypes.string,
    name: PropTypes.string,
  }),
};

const getPaymentPath = async (url) => {
  try {
    const {
      data: { data },
    } = await API.get(url);

    window.location.href = data.redirectUrl;
    // console.log({ data, url: data.redirectUrl });
  } catch (error) {
    // // eslint-disable-next-line
    console.debug(error);
  }
};

function PaymentBox({ finalAmount, payments, bankAccount }) {
  const [search] = useSearchParams();
  const isPaid = search.get('paid') === 'true';

  const finalValue = useMemo(
    () => formatCurrency(finalAmount.value, finalAmount.currencySymbol),
    [finalAmount],
  );

  const hasPaymentPath = useMemo(
    () => payments.some((payment) => payment?.paymentPath),
    [payments],
  );

  return (
    <Wrapper>
      <style>
        {`@media screen and (max-width: 500px) {
            .payments { min-width: 250px !important; }
            .payments div {flex-direction: column; flex-basis: fit-content; margin-top: 10px;}
            .payment { border-top: solid 1px #ddd; border-left: none !important; }
            header .printer {display:none}
            .paymentDate {display: flex; flex-direction: row !important; justify-content: center; gap: 5px; margin-top: 10px !important;}
          }
          @media print {.payments {border: 1px solid #ddd; border-radius: 16px;} .paymentDate {display: flex; flex-direction: row !important; justify-content: center; gap: 5px; margin-top: 10px !important;}}`}
      </style>
      <Summary>
        Wartość rezerwacji: <b>{finalValue}</b>
      </Summary>
      {payments.map((payment, index) => (
        <Summary className="payment" key={`paymentbox-${index}`}>
          <div key={`payment-row_${payment.name}`}>
            {payment.name}:{' '}
            <b>{formatCurrency(payment.amount.value, payment.amount.currencySymbol)}</b>
            {payment.paymentPath && !isPaid ? (
              <Button onClick={() => getPaymentPath(payment.paymentPath)}>Opłać teraz</Button>
            ) : (
              <div className="paymentDate">
                {payment.kind === 'cash_in_marina' ? (
                  <div className="paymentInMarina">płatna w porcie</div>
                ) : null}
                {payment.kind === 'bank_transfer' ? (
                  <div className="paymentLabel">
                    {payment.status === 'paid' ? 'zapłacona' : 'płatna do'}
                  </div>
                ) : null}
                {payment.kind === 'bank_transfer' ? (
                  <div className="paymentValue">
                    {payment.status === 'paid' ? payment.paidAt : payment.expireAt}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Summary>
      ))}
      {bankAccount && !isPaid && (
        <Summary className="payment account">
          <div>
            {hasPaymentPath
              ? 'W razie problemów z płatnością online, prosimy o przelew na konto:'
              : 'Prosimy o dokonanie wpłaty na konto:'}
            {bankAccount?.bankName && <strong>Bank: {bankAccount.bankName}</strong>}
            {bankAccount?.bankAccountNumber && <b>{bankAccount.bankAccountNumber}</b>}
            {bankAccount?.bicSwift && <strong>BIC/SWIFT: {bankAccount.bicSwift}</strong>}
            {bankAccount?.name && <strong>Odbiorca: {bankAccount.name}</strong>}
            <div className="paymentDate">
              <div className="paymentLabel">(w tytule przelewu proszę podać nr rezerwacji)</div>
            </div>
          </div>
        </Summary>
      )}
    </Wrapper>
  );
}

PaymentBox.defaultProps = {
  bankAccount: null,
};

export default memo(PaymentBox);
