import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, state }) => theme.statuses[state]}30;
  padding: 16px;
  border: 3px solid ${({ theme, state }) => theme.statuses[state]};
  border-radius: 16px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 30px;

  b {
    color: ${({ theme, state }) => theme.statuses[state]};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    margin-left: 8px;
    line-height: 30px;
    white-space: nowrap;
  }
`;

export const Col = styled.div``;
