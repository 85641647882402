import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '@components/ErrorBar/style';

function ErrorBar({ message }) {
  return <Wrapper>{message}</Wrapper>;
}

ErrorBar.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorBar;
