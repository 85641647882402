import React from 'react';
import Tick from '@assets/icons/tick.svg';
import { Icon, Text, Wrapper } from '@components/SuccessBox/style';

function SuccessBox() {
  return (
    <Wrapper>
      <Icon src={Tick} alt="Exclamation mark describing success" />
      <Text>Dziękujemy za podzielenie się z nami opinią</Text>
    </Wrapper>
  );
}

export default SuccessBox;
