/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { API, APIRoutes } from '@api';
import Button from '@components/Button';
import { FormWrapper, CommentSection, RatingSection } from '@components/ReviewForm/style';
import RatingController from '@components/ReviewForm/Controllers/RatingController';
import RecommendedController from '@components/ReviewForm/Controllers/RecommendedController';

ReviewForm.propTypes = {
  token: PropTypes.string.isRequired,
  reviewInvitationToken: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function ReviewForm({ token, reviewInvitationToken, dispatch }) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      recommended: 'true',
      comment: '',
      technicalConditionRating: null,
      cleanlinessRating: null,
      comfortEquipmentRating: null,
      serviceQualityRating: null,
    },
  });

  const onSubmit = async (review) => {
    try {
      dispatch({ type: 'toggleLoading' });
      await API.put(APIRoutes.SEND_REVIEW(token, reviewInvitationToken), { review });
      dispatch({ type: 'setSuccess' });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      dispatch({ type: 'setError' });
    } finally {
      dispatch({ type: 'toggleLoading' });
    }
  };

  const recommended = watch('recommended');
  const {
    comment: commentError,
    technicalConditionRating: technicalConditionRatingError,
    cleanlinessRating: cleanlinessRatingError,
    comfortEquipmentRating: comfortEquipmentRatingError,
    serviceQualityRating: serviceQualityRatingError,
  } = errors;

  return (
    <div>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <RatingSection>
          <RecommendedController
            name="recommended"
            label="Czy polecasz ten jacht?"
            register={register}
          />
          <RatingController
            name="technicalConditionRating"
            label="Stan techniczny"
            control={control}
            isInvalid={technicalConditionRatingError}
          />
          <RatingController
            name="cleanlinessRating"
            label="Czystość"
            control={control}
            isInvalid={cleanlinessRatingError}
          />
          <RatingController
            name="comfortEquipmentRating"
            label="Komfort i wyposażenie"
            control={control}
            isInvalid={comfortEquipmentRatingError}
          />
          <RatingController
            name="serviceQualityRating"
            label="Jakość obsługi przy przekazaniu jachtu"
            control={control}
            isInvalid={serviceQualityRatingError}
          />
          {(technicalConditionRatingError ||
            cleanlinessRatingError ||
            comfortEquipmentRatingError ||
            serviceQualityRatingError) && (
            <span className="error">Przydziel gwiazdki w każdej kategorii.</span>
          )}
          <CommentSection>
            <label htmlFor="comment">Wprowadź treść opinii</label>
            <textarea
              {...register('comment', { required: recommended === 'false' })}
              id="comment"
            />
            {commentError && (
              <span className="error">Napisz dlaczego jacht nie spełnił Twoich oczekiwań.</span>
            )}
          </CommentSection>
          <Button type="submit">Dodaj opinię</Button>
        </RatingSection>
      </FormWrapper>
    </div>
  );
}

export default ReviewForm;
