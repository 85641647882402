import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  height: fit-content;
  flex-flow: row wrap;
  justify-content: space-between;
  background: #dddddd30;
  padding: 10px;
  font-size: 14px;
  border-radius: 16px;
  font-weight: 500;

  b {
    display: block;
    font-size: 20px;
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }

  button {
    margin: 20px auto 0;
    width: auto;
  }
`;

export const Summary = styled.div`
  flex: 1 1 120px;
  position: relative;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  & + & {
    border-left: solid 1px #ddd;
  }

  .paymentDate {
    margin-top: 20px;
  }

  .paymentDate .paymentLabel {
    font-weight: normal;
    font-size: 12px;
  }

  .paymentInMarina {
    line-height: 40px;
  }

  &.account {
    width: 100%;
    flex: auto;
    margin-top: 2em;
    text-align: center;
    border: 0;

    strong {
      margin-top: 1em;
    }
  }
`;
