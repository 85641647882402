import React, { memo, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { reducer, initialState, init } from '@containers/Details/reducer';
import { API, APIRoutes } from '@api';
import StatusBar from '@components/StatusBar';
import PartnerBox from '@components/PartnerBox';
import NotFound from '@components/NotFound';
import { Loader } from '@styles/GlobalStyles';
import Logo from '@components/Logo';
import { Note, Row, Section } from '@containers/Details/style';
import PaymentBox from '@components/PaymentBox';
import { formatCurrency } from '@utils';
import DetailsHeaderBar from '@components/DetailsHeaderBar';

function DetailsPage() {
  const { token, reservationNumber, ...restParams } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState, init);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'toggleLoading' });
      try {
        const reservationNr = restParams['*']
          ? encodeURIComponent(`${reservationNumber}/${restParams['*']}`)
          : encodeURIComponent(reservationNumber);

        const {
          data: { reservation },
        } = await API.get(APIRoutes.RESERVATION(token, reservationNr));

        dispatch({ type: 'updateReservation', payload: reservation });
      } catch (error) {
        if (error.status === 404) {
          setNotFound(true);
        }
      } finally {
        dispatch({ type: 'toggleLoading' });
      }
    })();
  }, []);

  if (!token || !reservationNumber || notFound) {
    return <NotFound />;
  }

  if (state.isLoading) {
    return (
      <Loader>
        <Logo style={{ width: '60px', height: '60px' }} />
      </Loader>
    );
  }

  if (state.reservation) {
    const { reservation } = state;

    return (
      <main>
        <Helmet>
          <title>
            Rezerwacja jachtu {reservation.yachtTypeName} - {reservation.number}
          </title>
          <meta
            name="description"
            content={`Podsumowanie rezerwacji jachtu ${reservation.yachtTypeName} - ${reservation.number}`}
          />
        </Helmet>

        <DetailsHeaderBar />

        <PartnerBox partner={reservation.partner} />

        <StatusBar state={reservation.state} reservationNumber={reservation.number} />

        <Row>
          <Section className="lists">
            <div className="list">
              <h2>Rezerwacja</h2>
              <ul>
                <li>
                  Data utworzenia: <strong>{reservation.createdAt}</strong>
                </li>
                {reservation.clientName ? (
                  <li>
                    Rezerwujący: <strong>{reservation.clientName}</strong>
                  </li>
                ) : null}
              </ul>
            </div>

            <div className="list">
              <h2>
                Jacht{' '}
                {reservation.yachtZeglujUrl ? (
                  <a href={reservation.yachtZeglujUrl} target="_blank" rel="noreferrer">
                    zdjęcia i specyfikacja
                  </a>
                ) : null}
              </h2>

              <ul>
                <li>
                  Typ jachtu: <strong>{reservation.yachtTypeName}</strong>
                </li>
                <li>
                  Port:{' '}
                  <strong>
                    {reservation.marinaName}
                    {reservation.marinaAddress ? <br /> : null}
                    {reservation.marinaAddress}
                  </strong>
                </li>
              </ul>
            </div>

            <div className="list">
              <h2>Okres rezerwacji</h2>
              <ul>
                <li>
                  Termin czarteru:{' '}
                  <strong>
                    {reservation.startDate} - {reservation.endDate}
                  </strong>
                </li>
                <li>
                  Godzina wydania: <strong>{reservation.checkIn}</strong>
                </li>
                <li>
                  Godzina zwrotu: <strong>{reservation.checkOut}</strong>
                </li>
              </ul>
            </div>

            <div className="list">
              <h2>Opłaty dodatkowe</h2>
              <ul>
                <li>
                  Kaucja:{' '}
                  <strong>
                    {formatCurrency(
                      reservation.depositAmount.value,
                      reservation.depositAmount.currencySymbol,
                    )}
                  </strong>
                </li>
                {reservation.serviceFeeAmount.value > 0 ? (
                  <li>
                    Opłata za sprzątanie/serwis:{' '}
                    <strong>
                      {formatCurrency(
                        reservation.serviceFeeAmount.value,
                        reservation.serviceFeeAmount.currencySymbol,
                      )}
                    </strong>
                  </li>
                ) : null}
              </ul>
            </div>

            {reservation.notes ? (
              <div className="list">
                <h2>Uwagi do rezerwacji</h2>
                <ul>
                  <li>
                    <Note>{reservation.notes}</Note>
                  </li>
                </ul>
              </div>
            ) : null}
          </Section>

          <Section className="payments">
            <PaymentBox
              finalAmount={reservation.finalAmount}
              payments={reservation.payments}
              bankAccount={reservation.bankAccount}
            />
          </Section>
        </Row>
        <Row className="footer">
          <style>{`@media print {.footer {display: block !important;}}`}</style>
          <div>
            Dokument został wygenerowany <b>{`${new Date().toLocaleString('pl-PL')}`}</b> za
            pośrednictwem systemu{' '}
            <b>
              <a href="https://yachtcms.pl" target="_blank" rel="noreferrer">
                yachtCMS
              </a>
            </b>
          </div>
        </Row>
      </main>
    );
  }

  return null;
}

export default memo(DetailsPage);
