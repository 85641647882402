import styled from '@emotion/styled';
import { PAGE_MAX_WIDTH } from '@styles/GlobalStyles';

export const Wrapper = styled.header`
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH}px;
  margin: 0 auto;
  padding: 25px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.summary {
    display: flex;
    align-items: center;
  }
`;

export const Title = styled.h1`
  display: block;
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1.5em;
  text-shadow: 0 0 20px rgba(255, 255, 255, 1);

  span {
    white-space: nowrap;
  }
`;

export const Icon = styled.div`
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #0077b6;
  cursor: pointer;

  img {
    display: block;
    width: 30px;
  }
`;
