/* eslint-disable max-len */
import React from 'react';
import { Global, css } from '@emotion/react';
import reset from 'emotion-reset';
import styled from '@emotion/styled';

export const PAGE_MAX_WIDTH = 1200;

function GlobalStyle() {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600&display=swap');
        ${reset}

        *,
      *:after,
      *:before {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        body {
          color: #1d1d1f;
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='100' height='100'%3E%3Cpath fill='%230077b6' fill-opacity='0.05' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E");
          line-height: 20px;
          min-height: 100vh;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        b {
          font-weight: 500;
        }
      `}
    />
  );
}

export const PageWrapper = styled.div`
  padding: 15px;
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH}px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  position: relative;
  min-height: 200px;
`;

export const Loader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: ${({ theme }) => theme.loader.backgroundColor};

  svg {
    display: block;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default GlobalStyle;
